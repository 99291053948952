import { useEffect, useState } from "react"
import { useQuery } from "urql"
import queryString from "query-string"

const ProductsQuery = `
  query ($query: String!) {
    products(query: $query, first: 48) {
      edges {
        cursor
        node {
          title
          vendor
          productType
          handle
          priceRangeV2: priceRange {
            minVariantPrice {
              currencyCode
              amount
            }
            maxVariantPrice {
              currencyCode
              amount
            }
          }
          id
          images(first: 1) {
            edges {
              node {
                originalSrc
                width
                height
                altText
              }
            }
          }
          metafield(key: "navigation", namespace: "my_fields") {
          value
          key
        }
        }
      }
    }
  }
`

export function useProductSearch(term: string | null) {
  const [query, setQuery] = useState<string | null>("")

  const [result] = useQuery({
    query: ProductsQuery,
    variables: {
      query: `${query} AND ${process.env.GATSBY_SHOP_OWNER}`,
    },
  })

  useEffect(() => {
    const qs = queryString.stringify({ q: term || undefined })

    const url = new URL(window.location.href)
    url.search = qs
    url.hash = ""
    // @ts-ignore
    window.history.replaceState({}, null, url.toString())
  }, [result.data])

  useEffect(() => {
    setQuery(term)
  }, [term])

  return {
    data: term === "" || term === null ? null : result.data,
    fetching: result.fetching,
    error: result.error,
    noSearch: term === "" || term === null,
  }
}

export function getTermFromQueryString(query: string) {
  const { q } = queryString.parse(query)

  return q
}
