import React, { useCallback, useEffect, useState } from "react"
import styled from "styled-components"

const StyledForm = styled.form`
  input {
    position: relative;
    display: block;
    font-size: 1rem;
    box-sizing: border-box;
    border: 2px solid ${({ theme }) => theme.colors.primary};
    height: 2.5rem;
    padding: 0 1rem;
    margin: auto;
    border-radius: 0.5rem;
  }

  input[type="text"] {
    width: 18rem;
    background: #fff;

    &:focus {
      outline: none;
    }
  }

  input[type="submit"] {
    background-color: #fff;
    background-color: ${({ theme }) => theme.colors.primary};
    color: white;
    font-weight: 600;
    cursor: pointer;
    transition: all ease 500ms;
    margin-top: 0.5rem;

    &:hover {
      color: ${({ theme }) => theme.colors.primary};
      background-color: white;
    }
  }

  @media (min-width: 500px) {
    input {
      display: inline-block;
      border-top-left-radius: 0.5rem;
      border-bottom-left-radius: 0.5rem;
    }

    input[type="text"] {
      border-right: none;
      border-radius: 0.5rem 0 0 0.5rem;
    }

    input[type="submit"] {
      border-radius: 0 0.5rem 0.5rem 0;
      margin-top: 0;
    }
  }
`

const SearchInput = ({
  searchTerm,
  setSearchTerm,
}: {
  searchTerm: string
  setSearchTerm: React.Dispatch<React.SetStateAction<string>>
}) => {
  const [inputValue, setInputValue] = useState("")

  const handleSearchInput = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setInputValue(e.target.value)
    },
    [inputValue]
  )

  const handleSearchSubmit = (e: any) => {
    e.preventDefault()
    setSearchTerm(inputValue)
  }

  return (
    <StyledForm onSubmit={handleSearchSubmit}>
      <input
        type="text"
        onChange={handleSearchInput}
        value={inputValue}
        placeholder="Suchbegriff hier eingeben..."
        autoFocus
      />
      <input type="submit" value="Suchen" />
    </StyledForm>
  )
}

export default SearchInput
