import React from "react"
import { createClient, Provider as UrqlProvider } from "urql"

const urqlClient = createClient({
  url: `https://${process.env.GATSBY_SHOPIFY_STORE_URL}/api/2021-01/graphql.json`,
  fetchOptions: {
    headers: {
      "X-Shopify-Storefront-Access-Token": process.env
        .GATSBY_STOREFRONT_ACCESS_TOKEN as string,
    },
  },
})

export const SearchProvider: React.FC = ({ children }) => {
  return <UrqlProvider value={urqlClient}>{children}</UrqlProvider>
}
