import { getImage, GatsbyImage } from "gatsby-plugin-image"
import React, { useState } from "react"
import styled, { css } from "styled-components"

const FlipImage = ({ media, title }: { media: any; title: string }) => {
  const [flipped, setFlipped] = useState(false)

  const isFlippable = media.length > 1

  const frontImage = getImage(media[0].image)
  const backImage = isFlippable
    ? getImage(media[1].image)
    : getImage(media[0].image)

  return (
    <CardWrapper>
      <CardContainer>
        {isFlippable ? (
          <CardInner className={flipped ? "flipped" : ""}>
            <CardFront>
              <GatsbyImage image={frontImage!} alt={`${title} Vorderseite`} />
            </CardFront>
            <CardBack>
              <GatsbyImage image={backImage!} alt={`${title} Rückderseite`} />
            </CardBack>
          </CardInner>
        ) : (
          <CardInner>
            <CardFront>
              <GatsbyImage image={frontImage!} alt={title} />
            </CardFront>
          </CardInner>
        )}
      </CardContainer>
      {isFlippable && (
        <CardHover
          onMouseEnter={() => setFlipped(true)}
          onMouseLeave={() => setFlipped(false)}
        />
      )}
    </CardWrapper>
  )
}

export default FlipImage

const CardWrapper = styled.div`
  position: relative;
`

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  transition: z-index 500ms, transform 500ms;
  z-index: 0;
  -webkit-perspective: 1000px;
  perspective: 1000px;
  transform-style: preserve-3d;
  width: 200px;
  margin: auto;

  &.flipped {
    z-index: 1;
  }
`

const CardSide = css`
  width: 100%;
  min-width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  -moz-backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
`

const CardFront = styled.div`
  ${CardSide}
  z-index: 0;
  background: white;
`

const CardBack = styled.div`
  ${CardSide}
  transform: rotateY(-180deg) translate(100%, 0);
  background: #d7d7d7;
  z-index: 1;
`

const CardInner = styled.div`
  flex: 1;
  display: flex;
  transition: transform 500ms;
  transform-style: preserve-3d;

  &.flipped {
    transform: rotateY(180deg);
  }
`

const CardHover = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
`
