import React from "react"
import styled from "styled-components"
import { CombinedError } from "urql"

import ProductCard from "./ProductCard"

const SearchGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, 150px);
  justify-content: center;
  gap: 1rem;
`

const StyledHitCount = styled.div`
  margin: 3rem auto;
  font-family: ${({ theme }) => theme.fontHeading};

  span {
    color: ${({ theme }) => theme.colors.primary};
  }
`

const StyledInfo = styled.p`
  margin: 3rem auto;
  font-family: ${({ theme }) => theme.fontHeading};
`

interface Props {
  data: {
    products: {
      edges: [
        {
          cursor: string
          node: NodeTypes
        }
      ]
    }
  }
  fetching: boolean | undefined
  error: CombinedError | undefined
  noSearch: boolean
  hits: string | null
}

interface NodeTypes {
  title: string
  vendor: string
  productType: string
  handle: string
  priceRangeV2: {
    minVariantPrice: {
      currencyCode: string
      amount: number
    }
    maxVariantPrice: {
      currencyCode: string
      amount: number
    }
  }
  id: string
  images: {
    edges: {
      node: {
        originalSrc: string
        width: number
        height: number
        altText: string
      }
    }
  }
  metafield: {
    key: string
    value: string
  }
}

const SearchResult = ({ data, fetching, error, noSearch, hits }: Props) => {
  if (fetching) {
    return <p>Lade...</p>
  }

  if (error) {
    return <p>Error: {error.message}</p>
  }

  return (
    <>
      {noSearch ? (
        <StyledInfo>Suchbegriff eingeben (z.B. Shirts, Tassen..)</StyledInfo>
      ) : (
        <>
          <StyledHitCount>
            <p>
              Die Suche ergab <span>{hits}</span> Treffer:
            </p>
          </StyledHitCount>
          <SearchGrid>
            {data.products.edges.map(({ node }: { node: NodeTypes }) => {
              return (
                <ProductCard
                  key={node.id}
                  product={{
                    title: node.title,
                    images: node.images,
                    priceRangeV2: node.priceRangeV2,
                    navigation: node.metafield.value,
                    productType: node.productType,
                    handle: node.handle,
                  }}
                />
              )
            })}
          </SearchGrid>
        </>
      )}
    </>
  )
}

export default SearchResult
