import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image"
import { getShopifyImage } from "gatsby-source-shopify"
import React from "react"
import slugify from "slugify"
import styled from "styled-components"
import { formatPrice } from "../../utils/formatPrice"
import { StyledLink } from "../ProductGrid"

const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  h3 {
    font-weight: 400;
    font-size: 1rem;
  }
`

const Price = styled.p`
  display: inline-block;
  padding: 0.5rem 1rem;
  background: white;
  text-align: center;
  font-weight: 400;
  margin-bottom: 3rem;
  filter: ${({ theme }) => theme.dropShadow};
  transform: skew(-8deg);
  margin-top: 0;
`

interface PageProps {
  title: string
  images: any
  priceRangeV2: {
    minVariantPrice: {
      currencyCode: string
      amount: number
    }
  }
  navigation: string
  productType: string
  handle: string
}

const ProductCard = ({ product }: { product: PageProps }) => {
  const { title, images, priceRangeV2, navigation, productType, handle } =
    product

  const slugifiedNavItem = slugify(navigation, {
    lower: true,
    locale: "de",
  })

  const slugifiedProductType = slugify(productType, {
    lower: true,
    locale: "de",
  })

  const price = formatPrice(
    priceRangeV2.minVariantPrice.currencyCode,
    priceRangeV2.minVariantPrice.amount
  )

  let storefrontImageData = {} as IGatsbyImageData

  try {
    const storefrontImage = images?.edges[0]?.node
    storefrontImageData = getShopifyImage({
      image: storefrontImage,
      layout: "fixed",
      width: 150,
      height: 150,
    })
  } catch (e) {
    console.error(e)
  }

  return (
    <Card>
      <StyledLink
        to={`/shop/${slugifiedNavItem}/${slugifiedProductType}/${handle}`}
      >
        {/* Using normal img tag here, because GatsbyImage sometimes doesn't display the image. Most times on page refresh and inital search */}
        {/* <GatsbyImage
          image={storefrontImageData}
          alt={title}
          style={{ margin: "auto" }}
        /> */}
        <img
          src={images?.edges[0]?.node.originalSrc}
          alt={title}
          width="150"
          height="150"
        />
        <h3>{title}</h3>
        <Price>{price}</Price>
      </StyledLink>
    </Card>
  )
}

export default ProductCard
