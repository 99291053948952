import { graphql } from "gatsby"
import React, { useEffect, useState } from "react"
import styled from "styled-components"
import type { PageProps } from "gatsby"

import SearchInput from "../components/Search/SearchInput"
import { SearchProvider } from "../context/search-provider"
import { getTermFromQueryString, useProductSearch } from "../hooks/searchHooks"
import SearchResult from "../components/Search/SearchResult"

interface Props extends PageProps {
  data: {
    meta: {
      tags: string[]
      productTypes: string[]
    }
  }
}

const SearchContainer = styled.div`
  margin: 3rem auto;
  text-align: center;

  form {
    margin: 3rem auto 0;
  }
`

export const query = graphql`
  query MyQuery($shopOwner: String!) {
    meta: allShopifyProduct(filter: { vendor: { eq: $shopOwner } }) {
      productTypes: distinct(field: productType)
      tags: distinct(field: tags)
    }
  }
`

const SearchPage = (props: Props) => {
  const { search } = props.location

  const term = getTermFromQueryString(search)

  const [searchTerm, setSearchTerm] = useState<string>("")
  const [hits, setHits] = useState<string | null>(null)

  const { data, fetching, error, noSearch } = useProductSearch(searchTerm)

  useEffect(() => {
    if (term) {
      setSearchTerm(term)
    }
  }, [])

  useEffect(() => {
    setHits(String(data?.products?.edges?.length))
  }, [searchTerm, data])

  return (
    <SearchContainer>
      <h1>Suche</h1>
      <div>
        <SearchInput searchTerm={searchTerm} setSearchTerm={setSearchTerm} />

        <SearchResult
          data={data}
          fetching={fetching}
          error={error}
          noSearch={noSearch}
          hits={hits}
        />
      </div>
    </SearchContainer>
  )
}

export default function SearchPageTemplate(props: Props) {
  return (
    <SearchProvider>
      <SearchPage {...props} />
    </SearchProvider>
  )
}
