import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import slugify from "slugify"

import { motion } from "framer-motion"
import { formatPrice } from "../utils/formatPrice"
import FlipImage from "./FlipImage"

export const Product = ({ product }) => {
  let navItem = ""
  product.metafields.forEach(field => {
    if (field.key === "navigation") {
      navItem = field.value
    }
  })

  const slugifiedNavItem = slugify(navItem, {
    lower: true,
    locale: "de",
  })

  const slugifiedProductType = slugify(product.productType, {
    lower: true,
    locale: "de",
  })

  const price = formatPrice(
    product.priceRangeV2.minVariantPrice.currencyCode,
    product.priceRangeV2.minVariantPrice.amount
  )

  return (
    <StyledLink
      to={`/shop/${slugifiedNavItem}/${slugifiedProductType}/${product.handle}`}
      key={product.id}
    >
      <ProductCard>
        <FlipImage media={product.media} title={product.title} />
        <ProductCardInfo>
          <h3>{product.title}</h3>
          <Price>{price}</Price>
        </ProductCardInfo>
      </ProductCard>
    </StyledLink>
  )
}

const ProductGrid = ({ products }) => {
  return (
    <Grid>
      {products.map((product, i: number) => {
        return (
          <motion.div
            key={product.node.id}
            initial={{ opacity: 0, translateY: 50 }}
            animate={{ opacity: 1, translateY: 0 }}
            transition={{ duration: 0.6, delay: i * 0.1 }}
          >
            <Product product={product.node} />
          </motion.div>
        )
      })}
    </Grid>
  )
}

export const ProductGridRow = ({ products }) => {
  return (
    <Grid>
      {products.map(product => (
        <Product product={product} key={product.id} />
      ))}
    </Grid>
  )
}

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 250px));
  column-gap: 1rem;
  row-gap: 2rem;
  justify-content: center;
  max-width: ${({ theme }) => theme.maxWidth};
  margin: 0 auto;
`

export const StyledLink = styled(Link)`
  text-decoration: none;
  color: ${({ theme }) => theme.colors.text};
`

export const ProductCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  :hover h3 {
    color: ${({ theme }) => theme.colors.primary};
  }
`

export const ProductCardInfo = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;

  h3 {
    margin-bottom: 0.5rem;
    font-weight: 400;
  }

  p {
    margin: 0;
  }
`

const Price = styled.p`
  display: inline-block;
  padding: 0.5rem 1rem;
  background: white;
  text-align: center;
  font-weight: 400;
  margin-bottom: 3rem;
  filter: ${({ theme }) => theme.dropShadow};
  transform: skew(-8deg);
  margin-top: 0;
`

export default ProductGrid
